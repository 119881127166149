import * as React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="All posts" />
        <Bio />
        <p>No blog posts found.</p>
      </Layout>
    )
  }

  // Group posts by year
  const postsByYear = {}
  posts.forEach(post => {
    postsByYear[post.frontmatter.date] ||= []
    postsByYear[post.frontmatter.date].push(post)
  })

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="All posts" />
      <h1>Archive</h1>
      {Object.keys(postsByYear)
        .reverse()
        .map(year => {
          return (
            <>
              <h3>{year}</h3>
              <ol style={{ listStyle: `none` }}>
                {postsByYear[year].map((post, i) => {
                  const title = post.frontmatter.title || post.fields.slug
                  return (
                    <li key={post.fields.slug}>
                      <article
                        className="post-list-item"
                        itemScope
                        itemType="http://schema.org/Article"
                      >
                        <Link to={post.fields.slug} itemProp="url">
                          <span itemProp="headline">{title}</span>
                        </Link>
                      </article>
                    </li>
                  )
                })}
              </ol>
            </>
          )
        })}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "YYYY")
          title
          description
        }
      }
    }
  }
`
